html,body,#root{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
/* 滚动条优化 start */
::-webkit-scrollbar{
  width:5px;
  height:5px;
  }
  ::-webkit-scrollbar-track{
  background: #f6f6f6;
  border-radius:2px;
  }
  ::-webkit-scrollbar-thumb{
  background: #cdcdcd;
  border-radius:2px;
  }
  ::-webkit-scrollbar-thumb:hover{
  background: #666;
  }
  ::-webkit-scrollbar-corner {
  background: #f6f6f6;
  }
  .amap-sug-result {
    position: fixed;
    z-index: 9999999999;
    background-color: #fefefe;
    border: 1px solid #d1d1d1
}